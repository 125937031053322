import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { getAuthClients } from '../api/api'
import { AuthClient } from '../types/authClient'
import { noOpFunctionOverrideError } from '../utils/noOpFunctionOverrideError'

type AuthClientsContextProps = {
    authClients: AuthClient[]
    loading: boolean
    error: string | null
    updateAuthClient: (authClient: AuthClient) => void
    removeAuthClient: (authClientId: string) => void
    pushAuthClient: (authClient: AuthClient) => void
}

export const AuthClientsContext = createContext<AuthClientsContextProps>({
  authClients: [],
  loading: true,
  error: null,
  updateAuthClient: (authClient: AuthClient) => noOpFunctionOverrideError('updateAuthClient'),
  removeAuthClient: (authClientId: string) => noOpFunctionOverrideError('removeAuthClient'),
  pushAuthClient: (authClient: AuthClient) => noOpFunctionOverrideError('pushAuthClient'),
})

export const AuthClientsProvider = ({ children }: {children: ReactNode}) => {
  const [authClients, setAuthClients] = useState<AuthClient[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    getAuthClients()
      .then((authClients) => setAuthClients(authClients))
      .catch((error) => setError(error.toString()))
      .finally(() => setLoading(false))
  }, [])

  const updateAuthClient = (updatedAuthClient: AuthClient) =>
    setAuthClients((prevAuthClients) =>
      prevAuthClients.map(authClient =>
        authClient.id === updatedAuthClient.id ? updatedAuthClient : authClient
      )
    )

  const removeAuthClient = (authClientIdToRemove: string) =>
    setAuthClients((prevAuthClients) =>
      prevAuthClients.filter(authClient => authClient.id !== authClientIdToRemove)
    )

  const pushAuthClient = (authClientToPush: AuthClient) =>
    setAuthClients([...authClients, authClientToPush])

  return(
    <AuthClientsContext.Provider value={{ authClients, loading, error, updateAuthClient, removeAuthClient, pushAuthClient }}>
      {children}
    </AuthClientsContext.Provider>
  )
}