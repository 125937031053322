import { MultiSelectOption } from '../../components/MultiSelect/MultiSelect'

const isMultiSelectOption = (payload: unknown): payload is MultiSelectOption => {
  const p = payload as MultiSelectOption
  return typeof p === 'object'
    && typeof p.value === 'string'
    && typeof p.label === 'string'
}

export const isArrayOfMultiSelectOptions = (payload: unknown): payload is MultiSelectOption[] => {
  return Array.isArray(payload) && payload.every(isMultiSelectOption)
}