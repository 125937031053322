import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Titles from './views/Titles/Titles'
import './App.scss'
import Providers from './providers/Providers'
import Navbar from './components/Navbar/Navbar'
import AuthClients from './views/AuthClients/AuthClients'
import Modals from './Modals/Modals'
import ToastSection from './components/ToastSection/ToastSection'

const App = () =>
  <Providers>
    <Router>
      <Navbar />
      <div style={{ marginTop: '72px' }} />
      <Routes>
        <Route path="/titles" element={<Titles />} />
        <Route path="/authclients" element={<AuthClients />} />
        <Route path="*" element={<Navigate to="/titles" replace />} />
      </Routes>
      <ToastSection />
    </Router>
    <Modals />
  </Providers>

export default App
