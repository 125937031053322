import React, { useContext, useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import FileUploadField from '../components/FileUploadField/FileUploadField'
import { deleteImage, uploadImage } from '../api/api'
import { imageKeyToImageCategory } from '../utils/imageKeyToImageCategoryMapper'
import { ImageKey } from '../types/imageCategory'
import { useModalContext } from '../providers/ModalContext'
import { TitleDetailsContext } from '../providers/TitleDetailsContext'
import { getImageDimensionsFromFile } from '../utils/getImageDimensions'

export type ImageUploadModalProps = {
    title: string
    titleId: string
    resolution: string
    imageKey: ImageKey
}

const UploadImagesModal = ({
  title,
  titleId,
  resolution,
  imageKey
}: ImageUploadModalProps) => {
  const [uploading, setUploading] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const [existingImages, setExistingImages] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [deleting, setDeleting] = useState(false)
  const { closeModal } = useModalContext()
  const { titleDetails, pushImageWithKey, removeImageByIndex } = useContext(TitleDetailsContext)

  useEffect(() => {
    const matchingTitleDetails = titleDetails.find((details) => details.titleId === titleId)
    if (matchingTitleDetails && matchingTitleDetails[imageKey]) {
      setExistingImages(matchingTitleDetails[imageKey] ?? [])
    }
  }, [titleDetails])

  const handleFileChange = (e: any) => {
    const validExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml']
    const selectedFiles: File[] = Array.from(e.target.files || [])
    const filteredFiles = selectedFiles.filter((file) => validExtensions.includes(file.type))

    if (filteredFiles.length !== selectedFiles.length) {
      setErrorMessage('Only .jpg, .jpeg, .svg, and .png files are allowed.')
    } else {
      setErrorMessage(null)
    }
    setFiles([...files, ...filteredFiles])
  }

  const handleRemoveFile = async (index: number, isExisting = false): Promise<void> => {
    if (deleting) return
    if (isExisting) {
      const imageUrl = existingImages[index]
      setDeleting(true)

      deleteImage(titleId, imageKeyToImageCategory(imageKey), encodeURIComponent(imageUrl))
        .then(() => {
          removeImageByIndex({ titleId, imageKey: imageKey, index })
          setExistingImages((prev) => prev.filter((_, i) => i !== index))
          setSuccessMessage('Image deleted successfully!')
        })
        .catch(setErrorMessage)
        .finally(() => setDeleting(false))
    } else {
      setFiles((prev) => prev.filter((_, i) => i !== index))
    }
  }

  const uploadImageTask = async (file: File) => {
    const imageDimensions = await getImageDimensionsFromFile(file)
    const isMobile = imageDimensions.width < 768 || imageDimensions.height > imageDimensions.width

    await uploadImage(file, imageKeyToImageCategory(imageKey), titleId, isMobile)
      .then((response) => {
        pushImageWithKey({ titleId: titleId, imageKey: imageKey, imageUrl: response?.message ?? '' })
        setFiles([])
        setSuccessMessage(`Image uploaded successfully!`)
      })
      .catch(setErrorMessage)
  }

  const handleImageUpload = async () => {
    const uploadTasks: Promise<void>[] = []
    setUploading(true)

    files.forEach(file => {
      uploadTasks.push(uploadImageTask(file))
    })

    await Promise.all(uploadTasks)
      .catch(() => alert('One or more images failed to upload. Please check the console for details.'))
      .finally(() => setUploading(false))
  }

  const isSaveDisabled = uploading || (
    files.length === 0 && existingImages.length === 0
  )

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>Upload {imageKey} for {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {successMessage && (
          <div className="alert alert-info" role="alert">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        <FileUploadField
          label="Upload Image"
          description={`SVG, PNG or JPG (rec. ${resolution})`}
          files={files || []}
          onFileChange={handleFileChange}
          onRemoveFile={handleRemoveFile}
          existingImages={existingImages}
          onRemoveExistingImage={(index) => handleRemoveFile(index, true)}
          deleting={deleting}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal} disabled={uploading}>
                    Close
        </Button>
        <Button variant="primary" onClick={handleImageUpload} disabled={isSaveDisabled}>
          {uploading ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UploadImagesModal
