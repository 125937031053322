export type ImageDimensions = {
  width: number;
  height: number;
}

export const getImageDimensionsFromFile = (file: File): Promise<ImageDimensions> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const url = URL.createObjectURL(file)

    img.onload = function() {
      const dimensions = {
        width: img.width,
        height: img.height
      }

      URL.revokeObjectURL(url)
      resolve(dimensions)
    }

    img.onerror = function() {
      URL.revokeObjectURL(url)
      reject(new Error('There was an error loading the image.'))
    }

    img.src = url
  })
}

export const getImageDimensionsFromURL = (url: string): Promise<ImageDimensions> => {
  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight })
    }

    img.onerror = () => {
      reject(new Error('Failed to load image from URL.'))
    }

    img.src = url
  })
}