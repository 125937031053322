import { Country } from '../types/country'

export const countryCodes: Country[] = [
  {
    country_code: 'DE',
    country_id: '1',
    name: 'Germany',
    pk: 'COUNTRY#1',
    sk: 'COUNTRY#1',
    type: 'COUNTRY'
  },
  {
    country_code: 'US',
    country_id: '2',
    name: 'United States',
    pk: 'COUNTRY#2',
    sk: 'COUNTRY#2',
    type: 'COUNTRY'
  },
  {
    country_code: 'ES',
    country_id: '3',
    name: 'Spain',
    pk: 'COUNTRY#3',
    sk: 'COUNTRY#3',
    type: 'COUNTRY'
  },
  {
    country_code: 'JP',
    country_id: '4',
    name: 'Japan',
    pk: 'COUNTRY#4',
    sk: 'COUNTRY#4',
    type: 'COUNTRY'
  }
]

export const getRandomCountries = () => {
  const shuffled = countryCodes.map(e => e.country_code).sort(() => 0.5 - Math.random())
  const selectedCount = Math.floor(Math.random() * countryCodes.length)
  return shuffled.slice(0, selectedCount)
}