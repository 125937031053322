import React from 'react'
import { CustomTableMeta, EditCellProps } from '../types/TableTypes'
import styles from './EditCell.module.scss'

const EditCell = <T,>({ row, table }: EditCellProps<T>) => {
  const meta: CustomTableMeta = table.options.meta as CustomTableMeta

  const toggleEdit = () => {
    meta.setEditedRow && meta.setEditedRow(row.index)
  }

  const handleCancel = () => {
    meta.revertData && meta.revertData(row.index)
  }

  const handleSave = () => {
    meta.saveData && meta.saveData(row.index)
  }

  const removeRow = () => {
    meta.removeRow && meta.removeRow(row.index)
  }

  return (
    <div className={styles.editCell}>
      {meta.editingRowIndex === row.index ? (
        <>
          <button name="cancel" onClick={handleCancel} data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">
            <i className="bi bi-x-lg" />
          </button>
          <button name="done" onClick={handleSave} data-bs-toggle="tooltip" data-bs-placement="top" title="Save">
            <i className="bi bi-check-lg" />
          </button>
        </>
      ) : (
        <>
          <button onClick={toggleEdit} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
            <i className="bi bi-pencil-square" />
          </button>
          {meta.removeRow && (
            <button onClick={removeRow}>
              <i className="bi bi-trash3" />
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default EditCell