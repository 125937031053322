import { AuthClient } from '../types/authClient'
import { TitleDetails } from '../types/titleDetails'
import { Country } from '../types/country'
import { countryCodes, getRandomCountries } from './mockCountryCodes'
import { getRandomTitle } from './mockTitles'

export const getMockAuthClient = (): AuthClient => ({
  PK: 'AUTHCLIENT#00c0d053-7085-4053-8564-c61668f13dc8',
  SK: (Math.floor(Math.random() * 100) + 1).toString(),
  clientId: (Math.floor(Math.random() * 100) + 1).toString(),
  clientName: 'tradeTycoon',
  clientSecret: '$2a$10$MrHtsf6zcLJ/29EEUvzKuuvyExWaFyQy.7Jg2yoLxA7/K0/WQdCHC',
  id: `${(Math.floor(Math.random() * 100) + 1).toString()}_123`,
  pk: 'AUTHCLIENT#00c0d053-7085-4053-8564-c61668f13dc8',
  postLogoutRedirectUris: '123',
  redirectUris: '1233',
  sk: (Math.floor(Math.random() * 100) + 1).toString(),
  title: getTitleMocks()[0],
  titleId: (Math.floor(Math.random() * 100) + 1).toString()
})

export const getTitleMocks = (): TitleDetails[] => {
  return Array.from({ length: 30 }).map((_value, index) =>
    ({
      activePortfolioEndDate: null,
      activePortfolioStartDate: null,
      bannerImages: null,
      description: null,
      featuredGamesImages: null,
      gameCardImages: null,
      gameLaunchUrl: 'http://a-game-url.com',
      pk: 'TITLE#193',
      platforms: ['MOBILE'],
      punchline: null,
      recommendedGamesImages: ['https://wallpapers.com/images/hd/monkey-d-luffy-wide-smile-iuu9hxoxvbky3yo8.jpg'],
      shortTitle: 'tradetycoon',
      sk: 'TITLE#193',
      studio: 'Imperia Online',
      tags: ['RECOMMENDED'],
      title: getRandomTitle(),
      titleId: (index + 1).toString(),
      type: 'TITLE',
      unavailableCountries: getRandomCountries()
    })
  )
}

export const getAllCountriesMock = (): Country[] => countryCodes