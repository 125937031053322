import React, { useState } from 'react'
import {
  ColumnDef,
  ColumnSort,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel, getPaginationRowModel, getSortedRowModel, PaginationState,
  useReactTable,
} from '@tanstack/react-table'
import { CustomTableMeta, TableRow, TableV2Props } from './types/TableTypes'
import { MultiSelectOption } from '../MultiSelect/MultiSelect'
import styles from './TableV2.module.scss'
import { fuzzyFilter } from './tableUtils/FuzzyFilter'
import { Form } from 'react-bootstrap'
import Pagination from '../Pagination/Pagination'

const TableV2 = ({ rows, columns, onSave, actionButtons, onRemove, onChange }: TableV2Props) => {
  const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null)
  const [editingRow, setEditingRow] = useState<TableRow | null>(null)
  const [globalFilter, setGlobalFilter] = useState('')
  const [sortingState, setSortingState] = React.useState<ColumnSort[]>([])
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  const meta: CustomTableMeta = {
    editingRowIndex,
    setEditedRow: (rowIndex: number) => {
      setEditingRow(rows[rowIndex])
      setEditingRowIndex(rowIndex)
    },
    revertData: (rowIndex) => {
      editingRow && onChange(rowIndex, editingRow)
      setEditingRow(null)
      setEditingRowIndex(null)
    },
    saveData: (rowIndex: number) => {
      setEditingRow(null)
      setEditingRowIndex(null)
      onSave(rowIndex)
    },
    updateData: (rowIndex: number, columnId: string, value: string | number | MultiSelectOption[] | string[]) => {
      onChange(rowIndex, { ...rows[rowIndex], [columnId]: value })
    }
  }

  if (onRemove) {
    meta.removeRow = (rowIndex: number) => {
      onRemove && onRemove(rowIndex)
    }
  }

  const table = useReactTable({
    columns: columns as ColumnDef<unknown, any>[],
    data: rows,
    meta,
    filterFns: { fuzzy: fuzzyFilter, },
    state: { globalFilter, pagination, sorting: sortingState },
    onSortingChange: setSortingState,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    globalFilterFn: 'fuzzy',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    autoResetPageIndex: false
  })

  return (
    <div className={styles.table}>
      <div className={styles.actionSection}>
        <Form.Control
          type="text"
          placeholder="Search.."
          onChange={e => setGlobalFilter(e.target.value)}
          value={globalFilter}
          className={styles.searchInput}
        />
        {actionButtons}
      </div>
      <div className={styles.adminTableContainer}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={styles.header}
                    style={{ cursor: header.column.getCanSort() ? 'pointer' : 'default' }}
                    onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    <span className={header.column.getIsSorted() ? styles.sortIndicatorHidden : styles.sortIndicator}>
                      {header.column.getCanSort() ? ' ↑' : undefined}
                    </span>
                    {header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? ' ↓' : ' ↑') : undefined}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={styles.row}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination table={table}/>
    </div>
  )
}

export default TableV2