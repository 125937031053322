import { TitleDetailsProvider } from './TitleDetailsContext'
import React, { ReactNode } from 'react'
import { AuthClientsProvider } from './AuthClientContext'
import { CountriesProvider } from './CountriesContext'
import { ModalProvider } from './ModalContext'
import { ToastProvider } from './ToastContext'

const Providers = ({ children }: { children: ReactNode }) =>
  <ModalProvider>
    <ToastProvider>
      <AuthClientsProvider>
        <TitleDetailsProvider>
          <CountriesProvider>
            {children}
          </CountriesProvider>
        </TitleDetailsProvider>
      </AuthClientsProvider>
    </ToastProvider>
  </ModalProvider>

export default Providers