import { ImageCategory, ImageKey } from '../types/imageCategory'

export const imageKeyToImageCategory = (imageKey: ImageKey): ImageCategory => {
  switch (imageKey) {
    case 'bannerImages':
      return 'banner-images'
    case 'featuredGamesImages':
      return 'featured-game-images'
    case 'gameCardImages':
      return 'gamecard-images'
    case 'recommendedGamesImages':
      return 'recommended-game-images'
  }
}