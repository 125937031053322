import React, { useContext } from 'react'
import styles from './Titles.module.scss'
import TableV2 from '../../components/TableV2/TableV2'
import { createColumnHelper } from '@tanstack/react-table'
import TableCell from '../../components/TableV2/TableCell/TableCell'
import EditCell from '../../components/TableV2/EditCell/EditCell'
import { ImageModal, TableColumns, TableRow } from '../../components/TableV2/types/TableTypes'
import { TitleDetailsContext } from '../../providers/TitleDetailsContext'
import { TitleDetails } from '../../types/titleDetails'
import { updateTitleDetails as apiUpdateTitleDetails } from '../../api/api'
import { CountriesContext } from '../../providers/CountriesContext'
import { ToastContext } from '../../providers/ToastContext'
import { Country } from '../../types/country'
import { TAGS } from '../../types/tag'
import { PLATFORMS } from '../../types/platform'
import { MultiSelectOption } from '../../components/MultiSelect/MultiSelect'
import { mapStringArrayToMultiSelectOptions } from '../../utils/mappers'
import { TitleRow } from './titleRowType'
import { mapTableRowToTitleDetails, mapTitleDetailsToTableRows } from '../../types/tableMapper/titleDetails'

const Titles = () => {
  const { titleDetails, updateTitleDetails } = useContext(TitleDetailsContext)
  const { countries } = useContext(CountriesContext)
  const { addToast } = useContext(ToastContext)

  const mapCountriesToMultiSelectToOptions = (countries: Country[]): MultiSelectOption[] => {
    return countries.map((country) => ({
      value: country.country_code,
      label: country.name,
    })).sort((a, b) => a.label.localeCompare(b.label))
  }

  const rows = mapTitleDetailsToTableRows(titleDetails, countries)
  const countryMultiSelectOptions = mapCountriesToMultiSelectToOptions(countries)
  const tagMultiSelectOptions = mapStringArrayToMultiSelectOptions(TAGS)
  const platformMultiSelectOptions = mapStringArrayToMultiSelectOptions(PLATFORMS)

  const getImageModalMeta = ({ imageKey, resolution }: ImageModal) => {
    return {
      type: 'modal',
      isEditable: true,
      imageModal: {
        resolution,
        imageKey
      }
    }
  }

  const columnHelper = createColumnHelper<Partial<TitleDetails>>()
  const columns = [
    columnHelper.accessor('titleId', {
      header: 'Title ID',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: false,
      },
      enableSorting: true
    }),
    columnHelper.accessor('title', {
      header: 'Title',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: false
      },
      enableSorting: true
    }),
    columnHelper.accessor('studio', {
      header: 'Studio',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: false
      },
      enableSorting: true
    }),
    columnHelper.accessor('shortTitle', {
      header: 'Subtitle',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: true
      },
      enableSorting: false
    }),
    columnHelper.accessor('unavailableCountries', {
      header: 'Unavailable Countries',
      cell: TableCell,
      meta: {
        type: 'multiSelect',
        options: countryMultiSelectOptions,
        isEditable: true
      },
      enableSorting: false
    }),
    columnHelper.accessor('gameLaunchUrl', {
      header: 'Game URL',
      cell: TableCell,
      meta: {
        type: 'text',
        isEditable: true
      },
      enableSorting: false
    }),
    columnHelper.accessor('tags', {
      header: 'Game Tags',
      cell: TableCell,
      meta: {
        type: 'multiSelect',
        options: tagMultiSelectOptions,
        isEditable: true
      },
      enableSorting: false
    }),
    columnHelper.accessor('platforms', {
      header: 'Platforms',
      cell: TableCell,
      meta: {
        type: 'multiSelect',
        options: platformMultiSelectOptions,
        isEditable: true
      },
      enableSorting: false
    }),
    columnHelper.accessor('recommendedGamesImages', {
      header: 'Recommended Games Images',
      cell: TableCell,
      meta: getImageModalMeta({ resolution: '700x430px', imageKey: 'recommendedGamesImages' }),
      enableSorting: false
    }),
    columnHelper.accessor('featuredGamesImages', {
      header: 'Featured Games Images',
      cell: TableCell,
      meta: getImageModalMeta({ resolution: '700x430px', imageKey: 'featuredGamesImages' }),
      enableSorting: false
    }),
    columnHelper.accessor('bannerImages', {
      header: 'Auth Banner Images',
      cell: TableCell,
      meta: getImageModalMeta({ resolution: '700x430px', imageKey: 'bannerImages' }),
      enableSorting: false
    }),
    columnHelper.accessor('gameCardImages', {
      header: 'Game Card Images',
      cell: TableCell,
      meta: getImageModalMeta({ resolution: '700x430px', imageKey: 'gameCardImages' }),
      enableSorting: false
    }),
    // @todo add the rest of columns we want to display
    columnHelper.display({
      id: 'edit',
      cell: EditCell
    })
  ] as TableColumns

  const handleSave = (index: number) => {
    const newTitleDetails = titleDetails[index]

    apiUpdateTitleDetails(newTitleDetails.titleId, newTitleDetails)
      .then(() => {
        updateTitleDetails(newTitleDetails)
        addToast({ message: 'Successfully updated title details!', variant: 'success' })
      })
      .catch((e) => {
        console.error(e)
        addToast({ message: 'Failed to update tilte details. Please try again.', variant: 'danger' })
      })
  }

  const handleChange = (index: number, updatedRow: TableRow) => {
    updateTitleDetails({
      ...titleDetails[index],
      ...mapTableRowToTitleDetails({
        titleDetails,
        index,
        tableRow: updatedRow as TableRow<TitleRow>
      })
    })
  }

  return (
    <div className={styles.titles}>
      <h1>Titles</h1>
      <TableV2
        rows={rows}
        columns={columns}
        onSave={handleSave}
        onChange={handleChange}
      />
    </div>
  )
}

export default Titles