import React, { useContext } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { ToastContext } from '../../providers/ToastContext'
import { toastHeaders } from '../../utils/toastMessages'

const ToastSection = () => {
  const { toasts, removeToast } = useContext(ToastContext)

  return (
    <ToastContainer position="top-end" className="p-3">
      {toasts.map((toast) => {
        return (
          <Toast
            key={toast.id}
            bg={toast.variant}
            onClose={() => removeToast(toast.id)}
            show
            autohide
            delay={3000}
            animation
          >
            <Toast.Header>
              <strong className="me-auto">{toast.header || toastHeaders[toast.variant]}</strong>
            </Toast.Header>
            <Toast.Body className='text-white'>{toast.message}</Toast.Body>
          </Toast>
        )
      })}
    </ToastContainer>
  )
}

export default ToastSection
