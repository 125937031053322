import React from 'react'
import { Button } from 'react-bootstrap'
import styles from './Pagination.module.scss'
import { Table } from '@tanstack/react-table'

const Pagination = ({ table }: {table: Table<any>}) => {
  return (
    <div className={styles.pagination}>
      <Button
        onClick={() => table.firstPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<<'}
      </Button>
      <Button
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<'}
      </Button>
      <div className={styles.paginationIndex}>
        {table.getState().pagination.pageIndex + 1} of{' '}
        {table.getPageCount().toLocaleString()}
      </div>
      <Button
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>'}
      </Button>
      <Button
        onClick={() => table.lastPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>>'}
      </Button>
    </div>
  )
}

export default Pagination