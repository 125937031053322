import { ImageUploadModalProps } from './ImageUploadModal'

export const isImageUploadModalProps = (payload: unknown): payload is ImageUploadModalProps => {
  const p = payload as ImageUploadModalProps
  return typeof p === 'object'
    && p !== null
    && typeof p.titleId === 'string'
    && typeof p.title === 'string'
    && typeof p.resolution === 'string'
    && typeof p.imageKey === 'string'
}