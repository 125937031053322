import React, { useContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { AuthClientsContext } from '../providers/AuthClientContext'
import { useModalContext } from '../providers/ModalContext'
import { deleteAuthClient } from '../api/api'
import { ToastContext } from '../providers/ToastContext'

export type ConfirmDeleteModalProps = {
  authClientId: string
}

const ConfirmDeleteModal = ({ authClientId }: ConfirmDeleteModalProps) => {
  const { authClients } = useContext(AuthClientsContext)
  const { removeAuthClient } = useContext(AuthClientsContext)
  const { closeModal } = useModalContext()
  const { addToast } = useContext(ToastContext)
  const [deleting, setDeleting] = useState(false)
  const authClient = authClients.find((authClient) => authClient.id === authClientId)

  const handleDelete = () => {
    if (!authClient) return

    setDeleting(true)
    deleteAuthClient(authClient.id, authClient?.sk)
      .then(() => {
        removeAuthClient(authClient.id)
        addToast({ message: `Successfully removed AuthClient ${authClient.clientName}`, variant: 'success' })
      })
      .catch((e) => {
        console.log(e)
        addToast({ message: 'Failed to delete row. Please try again.', variant: 'danger' })
      })
      .finally(() => {
        setDeleting(false)
        closeModal()
      })
  }

  return (
    <Modal show onHide={closeModal} className='modal-md'>
      <Modal.Header><p>Are you sure you want to delete the auth client <b>{authClient?.title?.title ?? ''} (Title ID: {authClient?.titleId})?</b></p></Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          No
        </Button>
        <Button variant="primary" onClick={handleDelete}>
          {deleting ? 'Deleting...' : 'Yes'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmDeleteModal