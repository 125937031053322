import React, { useEffect, useState } from 'react'
import { Badge, Button, Modal } from 'react-bootstrap'
import styles from './ImageGallery.module.scss'
import { getImageDimensionsFromFile, getImageDimensionsFromURL, ImageDimensions } from '../../utils/getImageDimensions'

type ImageGalleryProps = {
    images?: string[]
    files?: File[]
    onRemoveImage?: (index: number) => Promise<void>
    onRemoveFile?: (index: number) => void
    deleting: boolean
}

const ImageGallery = ({
  images = [],
  files = [],
  onRemoveImage,
  onRemoveFile,
  deleting,
}: ImageGalleryProps) => {
  const [previewImage, setPreviewImage] = useState<string | null>(null)
  const [fileDimensions, setFileDimensions] = useState<ImageDimensions[]>([])
  const [imageDimensions, setImageDimensions] = useState<ImageDimensions[]>([])

  useEffect(() => {
    const filePromises = files.map(getImageDimensionsFromFile)
    const imagePromises = images.map(getImageDimensionsFromURL)
    Promise.all(filePromises).then(setFileDimensions)
    Promise.all(imagePromises).then(setImageDimensions)
  }, [files, images])

  const renderCard = (
    key: string,
    previewSource: string | File,
    title: string,
    onDelete: (() => void) | null = null,
    index: number,
    type: 'image' | 'file'
  ) => {
    // Dynamically generate preview URL for files
    const previewUrl = typeof previewSource === 'string'
      ? previewSource
      : URL.createObjectURL(previewSource)

    const getDimensions = (index: number, type: string) => 
      type === 'image' ? imageDimensions[index] : fileDimensions[index]

    const isMobile =
      (type === 'image' ? imageDimensions.length > index : fileDimensions.length > index) &&
      (() => {
        const { width, height } = getDimensions(index, type)
        return width < 768 || height > width
      })()

    return (
      <div key={key} className={styles.imageCard}>
        <div
          className={styles.imageThumbnail}
          onClick={() => setPreviewImage(previewUrl)}
          style={{ backgroundImage: `url(${previewUrl})` }}
        >
          <div className={styles.overlay}>
            <i className="bi bi-arrows-angle-expand" />
          </div>
        </div>

        <div className={styles.cardContent}>
          <button
            type="button"
            className={`${styles.imageTitle} btn btn-link`}
            onClick={() => setPreviewImage(previewUrl)}
          >
            {title}
          </button>
          <div className={styles.badgeContainer}>
            {isMobile
              ? <Badge bg="info" className={styles.badge}>Mobile</Badge>
              : <Badge bg="info" className={styles.badge}>Desktop</Badge>
            }
            <Badge bg="warning" className={styles.badge}>
              {type === 'file'
                ? fileDimensions.length > index
                  ? `${fileDimensions[index].width}x ${fileDimensions[index].height}px`
                  : ''
                : imageDimensions.length > index
                  ? `${imageDimensions[index].width}x ${imageDimensions[index].height}px`
                  : ''
              }
            </Badge>
          </div>
        </div>

        {onDelete && (
          <Button variant="outline-danger" size="sm" onClick={onDelete} disabled={deleting}>
            <i className="bi bi-trash"></i>
          </Button>
        )}
      </div>
    )
  }

  return (
    <div className={styles.imageCardContainer}>
      {/* Render Existing Images */}
      {images.map((image, index) =>
        renderCard(
          `image-${index}`,
          image,
          image.split('/').pop() || 'Unknown Image',
          onRemoveImage ? () => onRemoveImage(index) : null,
          index,
          'image'
        )
      )}

      {/* Render Uploaded Files */}
      {files.map((file, index) =>
        renderCard(
          `file-${index}`,
          file,
          file.name,
          onRemoveFile ? () => onRemoveFile(index) : null,
          index,
          'file'
        )
      )}

      {/* Image Preview Modal */}
      {previewImage && (
        <Modal
          show
          onHide={() => setPreviewImage(null)}
          centered
          className={styles.customModalTop}
        >
          <Modal.Header closeButton />
          <Modal.Body className="p-0 text-center">
            <img src={previewImage} alt="Preview" className={styles.previewImage} />
          </Modal.Body>
        </Modal>
      )
      }
    </div>
  )
}

export default ImageGallery