import React from 'react'
import { useModalContext } from '../providers/ModalContext'
import ImageUploadModal from './ImageUploadModal'
import { isImageUploadModalProps } from './ModalTypeGuards'
import CreateAuthClientModal from './CreateAuthClientModal'
import ConfirmDeleteModal from './ConfirmDeleteModal'

const ModalSection = () => {
  const activeModalComponent = getModalComponent()

  return <div>{activeModalComponent !== null && activeModalComponent}</div>
}

const getModalComponent = (): React.ReactElement | null => {
  const { modalContent, modalPayload } = useModalContext()

  switch(modalContent) {
    case 'imageUpload':
      return isImageUploadModalProps(modalPayload)
        ? <ImageUploadModal
          title={modalPayload.title}
          titleId={modalPayload.titleId}
          resolution={modalPayload.resolution}
          imageKey={modalPayload.imageKey}
        />
        : null
    case 'createAuthClient':
      return <CreateAuthClientModal />
    case 'confirmDelete':
      return typeof modalPayload === 'string'
        ? <ConfirmDeleteModal authClientId={modalPayload} />
        : null
    default:
      return null
  }
}

export default ModalSection