import { TableRow } from '../../components/TableV2/types/TableTypes'
import { TitleRow } from '../../views/Titles/titleRowType'
import { TitleDetails } from '../titleDetails'
import { MultiSelectOption } from '../../components/MultiSelect/MultiSelect'
import { Tag } from '../tag'
import { Platform } from '../platform'
import { mapStringArrayToMultiSelectOptions } from '../../utils/mappers'
import { Country } from '../country'

const getUnavailableCountriesRowByCountryCode = (countries: Country[], countryCode: string): MultiSelectOption => {
  const index = countries?.findIndex((country) => country.country_code === countryCode)

  return {
    value: countries[index]?.country_code ?? 'country not found',
    label: countries[index]?.name ?? 'country not found',
  }
}

export const mapTitleDetailsToTableRows = (
  titleDetails: TitleDetails[],
  countries: Country[]
): TableRow<TitleRow>[] =>
  titleDetails.map((item: TitleDetails) => ({
    title: item.title,
    titleId: item.titleId,
    studio: item.studio,
    shortTitle: item.shortTitle,
    gameLaunchUrl: item.gameLaunchUrl ?? '',
    tags: mapStringArrayToMultiSelectOptions(item.tags),
    platforms: mapStringArrayToMultiSelectOptions(item.platforms),
    unavailableCountries: item.unavailableCountries?.map((country) => getUnavailableCountriesRowByCountryCode(countries, country)) ?? [],
    recommendedGamesImages: item.recommendedGamesImages ?? [],
    featuredGamesImages: item.featuredGamesImages ?? [],
    bannerImages: item.bannerImages ?? [], // @todo we should introduce domain related tables (this for auth the rest right now is account)
    gameCardImages: item.gameCardImages ?? []
  }))

export const mapTableRowToTitleDetails = ({
  titleDetails,
  index,
  tableRow
}: {
  titleDetails: TitleDetails[]
  index: number
  tableRow: TableRow<TitleRow>
}): TitleDetails => ({
  ...titleDetails[index],
  unavailableCountries: tableRow.unavailableCountries.map((country: MultiSelectOption) => country.value),
  title: tableRow.title,
  titleId: tableRow.titleId,
  studio: tableRow.studio,
  shortTitle: tableRow.shortTitle,
  gameLaunchUrl: tableRow.gameLaunchUrl,
  tags: tableRow.tags.map((tag: MultiSelectOption) => tag.value as Tag),
  platforms: tableRow.platforms.map((platform: MultiSelectOption) => platform.value as Platform),
  recommendedGamesImages: tableRow.recommendedGamesImages,
  featuredGamesImages: tableRow.featuredGamesImages,
  bannerImages: tableRow.bannerImages,
  gameCardImages: tableRow.gameCardImages
})
