import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import reportWebVitals from './reportWebVitals'
import { worker } from './mocks/handlers'
import { getCsrfToken } from './api/api'
import { AxiosError } from 'axios'
import { StartResponse } from './api/responses'

export const checkSession = async (): Promise<void> => {
  if (process.env.REACT_APP_MOCK === 'true') {
    await worker.start({ onUnhandledRequest: 'bypass' })
  }

  await getCsrfToken()
    .then((csrfResponse) => {
      sessionStorage.setItem('csrfToken', csrfResponse.csrfToken)
    })
    .catch((e: AxiosError<StartResponse>) => {
      console.log(e, 'error')
      window.location.href = `${process.env.REACT_APP_API_URL}/saml2/authenticate/jumpcloud`
    })
}

checkSession().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root')!)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )

  reportWebVitals()
})
