import React, { createContext, ReactNode, useState } from 'react'
import { toastHeaders } from '../utils/toastMessages'
import { noOpFunctionOverrideError } from '../utils/noOpFunctionOverrideError'

type Toast = {
    id: string
    message: string
    variant: 'success' | 'danger' | 'info' | 'warning'
    header?: string
}

type ToastContextProps = {
    toasts: Toast[]
    addToast: (toast: Omit<Toast, 'id'>) => void
    removeToast: (id: string) => void
}

export const ToastContext = createContext<ToastContextProps>({
  toasts: [],
  addToast: (toast: Omit<Toast, 'id'>) => noOpFunctionOverrideError('addToast'),
  removeToast: (id: string) => noOpFunctionOverrideError('removeToast'),
})

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const addToast = (toast: Omit<Toast, 'id'>) => {
    const id = `${Date.now()}-${Math.random()}`
    const header = toast.header || toastHeaders[toast.variant]
    setToasts((prev) => [...prev, { ...toast, id, header }])

    setTimeout(() => removeToast(id), 5000)
  }

  const removeToast = (id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id))
  }

  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  )
}