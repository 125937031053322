import { http, HttpResponse } from 'msw'
import { getMockAuthClient, getAllCountriesMock, getTitleMocks } from './mocks'
import { setupWorker } from 'msw/browser'

const API_BASE_URL = process.env.REACT_APP_API_URL

export const handlers = [
  http.get(`${API_BASE_URL}/api/v1/start`, () => {
    return HttpResponse.json({ csrfToken: 'abc' })
  }),
  http.get(`${API_BASE_URL}/v1/admin/titles`, () => {
    return HttpResponse.json(getTitleMocks())
  }),
  http.put(`${API_BASE_URL}/v1/admin/titles/:titleId`, async ({ request }) => {
    return HttpResponse.json(await request.json())
  }),
  http.post(`${API_BASE_URL}/v1/admin/titles/:imageCategory/:titleId`, () => {
    return HttpResponse.json({ message: 'https://wallpapers.com/images/hd/monkey-d-luffy-wide-smile-iuu9hxoxvbky3yo8.jpg' })
  }),
  http.delete(`${API_BASE_URL}/v1/admin/titles/:imageCategory/:titleId`, () => {
    return HttpResponse.json()
  }),
  http.get(`${API_BASE_URL}/v1/admin/authclients`, () => {
    return HttpResponse.json([getMockAuthClient(), getMockAuthClient()])
  }),
  http.post(`${API_BASE_URL}/v1/admin/authclients`, () => {
    return HttpResponse.json(getMockAuthClient())
  }),
  http.put(`${API_BASE_URL}/v1/admin/authclients/:pk`, async ({ request }) => {
    // await delay(3000)
    return HttpResponse.json(await request.json())
  }),
  http.delete(`${API_BASE_URL}/v1/admin/authclients/:pk/:sk`, () => {
    return HttpResponse.json()
  }),
  http.get(`${API_BASE_URL}/v1/admin/countries`, () => {
    return HttpResponse.json(getAllCountriesMock())
  })
]

export const worker = setupWorker(...handlers)
