import React, { useEffect, useState } from 'react'
import { CustomColumnMeta, CustomTableMeta, ImageModal } from '../types/TableTypes'
import MultiSelect, { MultiSelectOption } from '../../MultiSelect/MultiSelect'
import { Button, Form } from 'react-bootstrap'
import { useModalContext } from '../../../providers/ModalContext'
import { ImageUploadModalProps } from '../../../Modals/ImageUploadModal'
import { isArrayOfMultiSelectOptions } from '../../../types/typeGuards/MultiSelectOptionTypeGuard'
import { isArrayOfString } from '../../../types/typeGuards/Array'
import { mapStringArrayToMultiSelectOptions } from '../../../utils/mappers'
import styles from './TableCell.module.scss'

export const getSelectedOptions = (
  fieldValue: unknown
): MultiSelectOption[] => {
  if (isArrayOfMultiSelectOptions(fieldValue)) {
    return fieldValue
  }
  if (Array.isArray(fieldValue)) {
    return mapStringArrayToMultiSelectOptions(fieldValue)
  }
  return []
}

const TableCell = ({ getValue, row, column, table }: any) => {
  const initialValue = getValue()
  const columnMeta = column.columnDef.meta as CustomColumnMeta
  const tableMeta: CustomTableMeta = table.options.meta
  const [value, setValue] = useState<string | number | MultiSelectOption[] | string[]>(initialValue)
  const { setModalContent, setModalPayload } = useModalContext()

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onBlur = () => {
    tableMeta.updateData && tableMeta.updateData(row.index, column.id, value)
  }

  const onMultiSelectChange = (values: MultiSelectOption[]) => {
    setValue(values)
    tableMeta.updateData && tableMeta.updateData(row.index, column.id, values)
  }

  const openModal = (imageModal: ImageModal) => {
    const payload: ImageUploadModalProps = {
      title: row.original.title,
      titleId: row.original.titleId,
      resolution: imageModal.resolution,
      imageKey: imageModal.imageKey
    }
    setModalPayload(payload)
    setModalContent('imageUpload')
  }

  const getColumnComponent = (meta: CustomColumnMeta): React.ReactElement => {
    switch (meta.type) {
      case 'modal':
        return <Button
          size='sm'
          onClick={() => meta.imageModal && openModal(meta.imageModal)}
        >Add/Edit Images</Button>
      case 'multiSelect':
        return <MultiSelect
          options={columnMeta.options}
          selectedOptions={getSelectedOptions(row.original[column.id])}
          onChange={onMultiSelectChange}
        />
      case 'text':
        return <Form.Control
          type="text"
          placeholder="Type here.."
          onChange={e => setValue(e.target.value)}
          value={typeof value === 'string' || typeof value === 'number' ? value : ''}
          className={styles.searchInput}
          onBlur={onBlur}
        />
    }
  }

  const getCellValue = () => {
    switch (true) {
      case Boolean(isArrayOfString(value)):
        return <div className={styles.cellValue}>{(value as string[]).length} Items</div>
      case Boolean(isArrayOfMultiSelectOptions(value)):
        return <div className={styles.cellValue}>{(value as MultiSelectOption[]).map((v) => v.label).join(', ')}</div>
      default:
        return <div className={styles.cellValue}>{value?.toString()}</div>
    }
  }

  if (tableMeta?.editingRowIndex === row.index && columnMeta.isEditable) {
    return getColumnComponent(columnMeta)
  }

  return getCellValue()
}

export default TableCell