import React, { useContext, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useModalContext } from '../providers/ModalContext'
import { createAuthClient } from '../api/api'
import { ToastContext } from '../providers/ToastContext'
import { TitleDetailsContext } from '../providers/TitleDetailsContext'
import styles from './CreateAuthClientModal.module.scss'
import { AuthClientsContext } from '../providers/AuthClientContext'
import Select from 'react-select'
import { SelectOption } from '../components/MultiSelect/types'
import { CreateAuthClientRequest } from '../api/requests'
import { TitleDetails } from '../types/titleDetails'

const CreateAuthClientModal = () => {
  const [isSaving, setIsSaving] = useState(false)
  const [selectedTitleOption, setSelectedTitleOption] = useState<SelectOption<TitleDetails> | null>(null)
  const [validated, setValidated] = useState(false)
  const [selectValidated, setSelectValidated] = useState(true)
  const [authClient, setAuthClient] = useState<Omit<CreateAuthClientRequest, 'titleId' | 'title'>>({
    clientSecret: '',
    clientName: '',
    redirectUris: '',
    postLogoutRedirectUris: '',
  })
  const { closeModal } = useModalContext()
  const { addToast } = useContext(ToastContext)
  const { titleDetails } = useContext(TitleDetailsContext)
  const { pushAuthClient } = useContext(AuthClientsContext)

  const titleSelectOptions: SelectOption<TitleDetails>[] = titleDetails.map((details) => ({
    label: `${details.titleId}: ${details.title}`,
    value: details
  }))

  const handleCreateAuthClient = async () => {
    setValidated(true)
    setSelectValidated(!!selectedTitleOption)

    const conditions: boolean[] = [
      authClient.clientName !== '',
      authClient.clientSecret !== '',
      authClient.redirectUris !== '',
      authClient.postLogoutRedirectUris !== ''
    ]

    if (conditions.every((condition) => !!condition) && selectedTitleOption) {
      const authClientRequest: CreateAuthClientRequest = {
        ...authClient,
        titleId: selectedTitleOption.value.titleId,
        title: selectedTitleOption.value,
      }
      setIsSaving(true)

      await createAuthClient(authClientRequest)
        .then((authClient) => {
          pushAuthClient(authClient)
          closeModal()
          addToast({ message: 'Successfully created authClient', variant: 'success' })
        })
        .catch((e) => {
          console.error(e)
          addToast({ message: 'Failed to create authClient', variant: 'danger' })
        })
        .finally(() => setIsSaving(false))
    }
  }

  return (
    <Modal show onHide={closeModal} className='modal-md'>
      <Modal.Header>
        <Modal.Title>Create AuthClient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} className={styles.authForm}>
          <Form.Group controlId="titleId">
            <Form.Label>Title ID</Form.Label>
            <Select
              className={selectValidated ? '' : 'is-invalid'}
              value={selectedTitleOption}
              options={titleSelectOptions}
              onChange={value => {
                setSelectedTitleOption(value)
                setSelectValidated(true)
              }}
              placeholder="Select a title"
            />
            {!selectValidated && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                  Please select a title.
              </div>
            )}
          </Form.Group>
          <Form.Group controlId="clientSecret">
            <Form.Label>Client Secret</Form.Label>
            <Form.Control
              type="text"
              value={authClient.clientSecret}
              onChange={(e) => setAuthClient({ ...authClient, clientSecret: e.target.value })}
              placeholder="Enter client secret"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a client secret.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="clientName">
            <Form.Label>Client Name</Form.Label>
            <Form.Control
              type="text"
              value={authClient.clientName}
              onChange={(e) => setAuthClient({ ...authClient, clientName: e.target.value })}
              placeholder="Enter client name"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a client name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="redirectUris">
            <Form.Label>Redirect URIs</Form.Label>
            <Form.Control
              type="text"
              value={authClient.redirectUris}
              onChange={(e) => setAuthClient({ ...authClient, redirectUris: e.target.value })}
              placeholder="Enter redirect URIs, comma separated"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide Redirect URIs.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="postLogoutRedirectUris">
            <Form.Label>Post Logout Redirect URIs</Form.Label>
            <Form.Control
              type="text"
              value={authClient.postLogoutRedirectUris}
              onChange={(e) => setAuthClient({ ...authClient, postLogoutRedirectUris: e.target.value })}
              placeholder="Enter post logout redirect URIs, comma separated"
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide Post Logout Redirect URIs.
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal} disabled={isSaving}>
          Close
        </Button>
        <Button variant="primary" onClick={handleCreateAuthClient} disabled={isSaving}>
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateAuthClientModal