import Select from 'react-select'
import React, { useEffect, useRef, useState } from 'react'

export type MultiSelectOption = {value: string, label: string}

type MultiSelectProps = {
    selectedOptions: MultiSelectOption[]
    options: MultiSelectOption[]
    autoFocus?: boolean
    onChange: (values: MultiSelectOption[]) => void
    onBlur?: () => void
}

const MultiSelect = (props: MultiSelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiSelectOption[]>(props.selectedOptions)
  const [menuPlacement, setMenuPlacement] = useState<'bottom' | 'top'>('bottom')
  const selectRef = useRef<HTMLDivElement | null>(null)

  const updateMenuPlacement = () => {
    if (selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect()
      const spaceAbove = rect.top
      const spaceBelow = window.innerHeight - rect.bottom

      if (spaceBelow < 300 && spaceAbove > spaceBelow) {
        setMenuPlacement('top')
      } else {
        setMenuPlacement('bottom')
      }
    }
  }

  useEffect(() => {
    updateMenuPlacement()
    window.addEventListener('resize', updateMenuPlacement)

    return () => window.removeEventListener('resize', updateMenuPlacement)
  }, [])

  const handleChange = (selectedValue: MultiSelectOption[]) => {
    setSelectedOptions(selectedValue)
    props.onChange(selectedValue)
  }

  return (
    <div onClick={e => e.stopPropagation()} ref={selectRef}>
      <Select
        isMulti
        autoFocus={props.autoFocus}
        value={selectedOptions}
        onChange={(payload) => handleChange(payload as MultiSelectOption[])}
        onBlur={props.onBlur}
        options={props.options}
        menuPlacement={menuPlacement}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 99 }) }}
      />
    </div>
  )
}

export default MultiSelect