import React, { createContext, ReactNode, useState, useContext } from 'react'
import { noOpFunctionOverrideError } from '../utils/noOpFunctionOverrideError'

type ModalContent = 'imageUpload' | 'createAuthClient' | 'confirmDelete' | null

export type ModalContextProps<T = unknown> = {
    modalContent: ModalContent
    closeModal: () => void
    setModalContent: (modalContent: ModalContent) => void
    modalPayload: T | null
    setModalPayload: (payload: T) => void
}

const ModalContext = createContext<ModalContextProps>({
  modalContent: null,
  closeModal: () => noOpFunctionOverrideError('closeModal'),
  setModalContent: (modalContent: ModalContent) => noOpFunctionOverrideError('setModalContent'),
  modalPayload: null,
  setModalPayload: (payload: unknown) => noOpFunctionOverrideError('setModalPayload'),
})
export const useModalContext = <T,>() => useContext(ModalContext) as ModalContextProps<T>

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalContent, setModalContent] = useState<ModalContent>(null)
  const [modalPayload, setModalPayload] = useState<unknown>(null)
  const closeModal = () => {
    setModalContent(null)
    setModalPayload(null)
  }

  return (
    <ModalContext.Provider value={{ modalContent, setModalContent, modalPayload, setModalPayload, closeModal }}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContext