import React from 'react'
import { Form, Button } from 'react-bootstrap'
import ImageGallery from '../ImageGallery/ImageGallery'
import styles from './FileUploadField.module.scss'

type FileUploadFieldProps = {
  label: string
  description: string
  files: File[]
  existingImages: string[]
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onRemoveFile: (index: number) => void
  onRemoveExistingImage: (index: number) => Promise<void>
  deleting: boolean
}

const FileUploadField = ({ label, description, files, existingImages = [], onFileChange, onRemoveFile, onRemoveExistingImage, deleting }: FileUploadFieldProps) => {
  return (
    <>
      <div className={`${styles.uploadContainer} mb-3`}>
        <div className={styles.iconAndText}>
          <i className="bi bi-image" style={{ fontSize: '1.5rem', marginRight: '0.5rem', color: '#6c757d' }}></i>
          <div>
            <p className="mb-1">{label}</p>
            <p className="text-muted small">{description}</p>
          </div>
        </div>
        <Button variant="outline-dark" size="sm" as="label">
          Browse
          <Form.Control
            type="file"
            onChange={onFileChange}
            hidden
            multiple
            accept=".jpg,.jpeg,.png,.svg"
          />
        </Button>
      </div>

      {(existingImages.length > 0 || files.length > 0) && (
        <ImageGallery
          images={existingImages}
          files={files}
          onRemoveImage={onRemoveExistingImage}
          onRemoveFile={onRemoveFile}
          deleting={deleting}
        />
      )}
    </>
  )
}

export default FileUploadField