import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { TitleDetails } from '../types/titleDetails'
import { getTitles } from '../api/api'
import { noOpFunctionOverrideError } from '../utils/noOpFunctionOverrideError'
import { ImageKey } from '../types/imageCategory'

type TitleDetailsContextProps = {
  titleDetails: TitleDetails[]
  loading: boolean
  error: string | null
  updateTitleDetails: (titleDetails: TitleDetails) => void
  pushImageWithKey: (payload: PushImageWithKeyPayload) => void
  removeImageByIndex: (payload: RemoveImageByIndex) => void
}

type PushImageWithKeyPayload = {
  titleId: string
  imageUrl: string
  imageKey: ImageKey
}

type RemoveImageByIndex = {
  titleId: string
  imageKey: ImageKey
  index: number
}

export const TitleDetailsContext = createContext<TitleDetailsContextProps>({
  titleDetails: [],
  loading: true,
  error: null,
  updateTitleDetails: (titleDetails: TitleDetails) => noOpFunctionOverrideError('updateTitleDetails'),
  pushImageWithKey: (payload: PushImageWithKeyPayload) => noOpFunctionOverrideError('pushImagesWithKey'),
  removeImageByIndex: (payload: RemoveImageByIndex) => noOpFunctionOverrideError('removeImageByIndex'),
})

export const TitleDetailsProvider = ({ children }: {children: ReactNode}) => {
  const [titleDetails, setTitleDetails] = useState<TitleDetails[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    getTitles()
      .then((titleDetails) => setTitleDetails(titleDetails))
      .catch((error) => setError(error.toString()))
      .finally(() => setLoading(false))
  }, [])

  const updateTitleDetails = (updatedTitleDetails: TitleDetails) => {
    setTitleDetails((prevTitleDetails) =>
      prevTitleDetails.map(titleDetails =>
        titleDetails.titleId === updatedTitleDetails.titleId ? updatedTitleDetails : titleDetails
      )
    )
  }

  const pushImageWithKey = ({ titleId, imageKey, imageUrl }: PushImageWithKeyPayload) => {
    const matchingTitleDetails = titleDetails.find((detail) => detail.titleId === titleId)
    const matchingImages = (matchingTitleDetails && matchingTitleDetails[imageKey] as string[]) ?? []

    if (matchingTitleDetails) {
      matchingImages.push(imageUrl)
      matchingTitleDetails[imageKey] = matchingImages

      setTitleDetails((prevTitleDetails) =>
        prevTitleDetails.map(titleDetails =>
          titleDetails.titleId === titleId ? { ...titleDetails, ...matchingTitleDetails } : titleDetails
        )
      )
    }
  }

  const removeImageByIndex = ({ titleId, imageKey, index }: RemoveImageByIndex) => {
    const matchingTitleDetails = titleDetails.find((detail) => detail.titleId === titleId)
    const matchingImages = (matchingTitleDetails && matchingTitleDetails[imageKey] as string[]) ?? []

    if (matchingTitleDetails) {
      setTitleDetails((prevTitleDetails) =>
        prevTitleDetails.map(titleDetails =>
          titleDetails.titleId === titleId
            ? {
              ...titleDetails,
              [imageKey]: matchingImages.filter((_, i) => i !== index)
            }
            : titleDetails
        )
      )
    }
  }

  return(
    <TitleDetailsContext.Provider value={{
      titleDetails,
      loading,
      error,
      updateTitleDetails,
      pushImageWithKey,
      removeImageByIndex
    }}>
      {children}
    </TitleDetailsContext.Provider>
  )
}