import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { getCountries } from '../api/api'
import { Country } from '../types/country'

type CountriesContextProps = {
    countries: Country[]
    loading: boolean
    error: string | null
}

export const CountriesContext = createContext<CountriesContextProps>({
  countries: [],
  loading: true,
  error: null
})

export const CountriesProvider = ({ children }: {children: ReactNode}) => {
  const [countries, setCountries] = useState<Country[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    getCountries()
      .then((countries) => setCountries(countries ?? []))
      .catch((error) => setError(error.toString()))
      .finally(() => setLoading(false))
  }, [])

  return(
    <CountriesContext.Provider value={{ countries, loading, error }}>
      {children}
    </CountriesContext.Provider>
  )
}